import { ReactNode } from "react";

interface AlertProps {
  message: ReactNode;
  type: "error" | "success" | "info";
}

export const Alert = ({ message, type }: AlertProps) => {
  const alertType = {
    info: "bg-blue-100/20 text-blue-50",
    error: "bg-red-100/20 text-red-50",
    success: "bg-green-100/20 text-green-50",
  }[type];

  const icon = {
    info: "ℹ️",
    error: "❌",
    success: "✅",
  }[type];

  return (
    <div className={`p-4 rounded-lg ${alertType}`}>
      <div className="flex items-center gap-4">
        <span>{icon}</span>
        {message}
      </div>
    </div>
  );
};
